<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>热门活动</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="活动名称">
                <el-input v-model="search.data_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="活动类型">
                <el-select
                  v-model="search.source_tag"
                  style="width: 100%"
                  placeholder="请选择类型"
                >
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="门店列表" value="store_list"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态">
                <el-select
                  v-model="search.state"
                  style="width: 100%"
                  placeholder="请选择状态"
                >
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="使用" value="1"></el-option>
                  <el-option label="禁用" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运动品类">
                <el-input v-model="search.motion_category"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运营标签">
                <el-input v-model="search.operate_tag"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="searchtime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
            <el-button
              v-if="is_auth('weapphome.bannerpage.isadd')"
              icon="el-icon-plus"
              size="medium"
              type="primary"
              @click="addActivity(null)"
              >添加活动
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="id" label="活动id" />
        <el-table-column prop="data_name" label="活动名称" width="180"> </el-table-column>
        <el-table-column prop="photo" label="背景图" width="130">
          <template slot-scope="scope">
            <div v-if="!is_empty(scope.row.data_bg_img_url)">
              <el-image
                style="width: 22px; height: 22px; margin-right: 4px"
                :src="scope.row.data_bg_img_url"
                fit="contain"
                :preview-src-list="[scope.row.data_bg_img_url]"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="publish_state" label="门店" min-width="200">
          <template slot-scope="scope">
            <el-tag
              :key="item.index"
              v-for="item in scope.row.data_info.store_all"
              class="form-tag_colour-tag"
              effect="dark"
              size="mini"
            >
              {{ item }}
            </el-tag>
          </template> </el-table-column
        >
        <el-table-column prop="operate_tag" label="运营标签" min-width="200">
          <template slot-scope="scope">
            <div class="form-tag_colour-div">
                <el-tag
                    :key="item.id"
                    v-for="item in scope.row.data_info.operate_tag"
                    class="form-tag_colour-tag"
                    effect="dark"
                    >
                    {{ item.name }}
                </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="motion_category_two" label="运动品类" min-width="200">
          <template slot-scope="scope">
            <div class="form-tag_colour-div">
                <el-tag
                    :key="item.id"
                    v-for="item in scope.row.data_info.motion_category_two"
                    class="form-tag_colour-tag"
                    effect="dark"
                    >
                    {{ item.name }}
                </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" width="180">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1" type="success">使用</el-tag>
            <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('weapphome.bannerpage.isadd')"
              @click="addActivity(scope.row.id)"
              type="primary"
              size="mini"
              >编辑
            </el-button>
            <el-button
              v-if="is_auth('weapphome.bannerpage.isenable') && scope.row.state === 2"
              @click="activityControl(scope.row.id, 'open')"
              type="primary"
              size="mini"
              >开启
            </el-button>
            <el-button
              v-if="is_auth('weapphome.bannerpage.isdisable') && scope.row.state === 1"
              @click="activityControl(scope.row.id, 'close')"
              type="danger"
              size="mini"
              >禁用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getList"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.init();
  },
  data() {
    return {
      page_name: "发现页热门活动列表",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      searchtime: null, // 时间筛选条件
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
    };
  },
  methods: {
    // 判断是否为空
    is_empty(auth) {
      return this.Tool.is_empty(auth);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        state: "",
        source_tag: "",
        motion_category: "",
        operate_tag: "",
      };
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getList();
    },
    // 获取列表数据
    getList() {
      const { searchtime } = this;
      let postdata = {
        api_name: "weapphome.bannerpage.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      if (searchtime) {
        postdata["start_at"] = searchtime[0];
        postdata["end_at"] = searchtime[1];
      }
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 发布新的活动
    addActivity(id) {
      console.log(id);
      let json = {
        path: "/weapphome/hotActivity/edit",
      };
      if (id) {
        json["query"] = {
          id,
        };
      }
      this.$router.push({ ...json });
    },
    // 活动开启或禁用
    activityControl(id, type) {
      let postdata = {
        api_name:
          type === "open"
            ? "weapphome.bannerpage.isenable"
            : "weapphome.bannerpage.isdisable",
        token: this.Tool.get_l_cache("token"),
        id,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: `${type === "open" ? "开启" : "禁用"}成功`,
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.init();
            },
          });
        }
      });
    },
  },
};
</script>
